body {
  margin: 0;
  font-family: "Montserrat", "sans-serif" !important;
}

*::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #707070;
  border-radius: 10px;
}

#notistack-snackbar {
  padding-right: 20px;
}


